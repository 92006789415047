<template>
  <v-app>
    <v-main class="grey lighten-2">
      <Form/>
    </v-main>
  </v-app>
</template>

<script>
import Form from "@/components/Form";


export default {
  name: "Formview",
  components: {
    Form,
  },

  data: () => ({
    //
  })
};
</script>
