<template>
  <v-container fluid>
    <v-row align="center" justify="center" v-if="reports == null || reports.length <= 1">
      <p class="class=text-right headline font-weight-regular title font-weight-light">Não há nenhum pedido a ser aprovado no momento.</p>
    </v-row>
    
    <v-form ref="form" v-for="report in reports[page]" :key="report">
      <v-row align="center" justify="center">
        <v-col cols="7">
          <v-container align-center class="white rounded-lg">
            <div class="mx-3">
              <v-row v-if="report.jurid_aprov==='1'">
                <v-avatar
                  class="mx-3"
                  color="green"
                  size="20"
                >
                </v-avatar>
                Aprovado
              </v-row>

              <v-row v-if="report.jurid_aprov.length!==1">
                <v-avatar
                  class="mx-3"
                  color="red"
                  size="20"
                >
                </v-avatar>
                Reprovado por jurídico: "{{report.jurid_aprov}}"
              </v-row>

              <v-row class="mx-1 mt-3">
                <p class="headline font-weight-light">{{ report.report }}</p>
                <v-spacer></v-spacer>
                <p class="text-right headline font-weight-bold">
                  ID: {{ report.id }}
                </p>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="6">
                  <v-text-field
                    label="Data Inicial"
                    outlined
                    disabled
                    color="light-green lighten-1"
                    v-model="report.time[0]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Data Final"
                    outlined
                    disabled
                    color="light-green lighten-1"
                    v-model="report.time[1]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <!--EMPRESA-->
            <v-row class="mx-3 mt-3">
              <p class="title font-weight-light">Empresa</p>
            </v-row>
            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-flex xs12>
                <v-combobox multiple
                          v-model="report.names"
                          label="Nomes das empresas"
                          append-icon
                          outlined
                          disabled
                          chips
                          color="green"
                          deletable-chips
                          class="tag-input">
                </v-combobox>
              </v-flex>
              </v-col>
            </v-row>
            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-flex xs12>
                <v-combobox multiple
                          v-model="report.links"
                          label="Links"
                          append-icon
                          outlined
                          disabled
                          chips
                          color="green"
                          deletable-chips
                          class="tag-input">
                </v-combobox>
              </v-flex>
              </v-col>
            </v-row>
            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12" v-for="(_, idx) in report.problemsKeywords" :key="idx">
                <v-flex xs12>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      disabled
                      auto-grow
                      :label="`Palavras-chave ${report.problems[idx]}`"
                      v-model="report.problemsKeywords[idx]"
                    ></v-textarea>
                  </v-col>
                </v-flex>
              </v-col>              
            </v-row>
            <!--Ofício-->
            <v-row class="mx-3 mt-3">
              <p class="title font-weight-light">Ofício</p>
            </v-row>
            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-card
                outlined
                max-height="70">
                  <v-row>
                    <v-col cols="2">
                      <v-img max-height="45" class="ma-0 ml-5 pa-0" max-width="45" src="../assets/pdf.png"></v-img>
                    </v-col>
                    <v-col cols="7" class="ma-0 pa-0">
                      <v-list-item two-line class="ma-0 pa-0">
                        <v-list-item-content>
                          <v-card-text class="h6 ma-0 pa-0">
                            Ofício
                          </v-card-text>
                          <v-list-item-subtitle>até 20mb</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="white--text"
                        :href="report.files.pdf"
                        download
                        rounded
                        color="cyan"
                        target="_blank"
                      >
                        Download
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

            </v-row>
            <!--planilha-->
            <v-row class="mx-3 mt-3">
              <p class="title font-weight-light">Planilha</p>
            </v-row>
            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-card
                outlined
                max-height="70">
                  <v-row>
                    <v-col cols="2">
                      <v-img max-height="45" class="ma-0 ml-5 pa-0" max-width="45" src="../assets/xls.png"></v-img>
                    </v-col>
                    <v-col cols="7" class="ma-0 pa-0">
                      <v-list-item two-line class="ma-0 pa-0">
                        <v-list-item-content>
                          <v-card-text class="h6 ma-0 pa-0">
                            Planilha
                          </v-card-text>
                          <v-list-item-subtitle>até 50mb</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        v-if="report.files.sheet!==''"
                        class="white--text"
                        :href="report.files.sheet"
                        download
                        rounded
                        color="cyan"
                        target="_blank"
                      >
                        Download
                      </v-btn>
                      <v-btn
                        v-if="report.files.sheet===''"
                        class="white--text"
                        download
                        rounded
                        color="cyan"
                        disabled
                      >
                        Download
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <!--Download CSV -->

            <v-row class="mx-3 mt-3">
              <p class="title font-weight-light">CSV</p>
            </v-row>
            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-card
                outlined
                max-height="70">
                  <v-row>
                    <v-col cols="2">
                      <v-img max-height="45" class="ma-0 ml-5 pa-0" max-width="45" src="../assets/csv.png"></v-img>
                    </v-col>
                    <v-col cols="7" class="ma-0 pa-0">
                      <v-list-item two-line class="ma-0 pa-0">
                        <v-list-item-content>
                          <v-card-text class="h6 ma-0 pa-0">
                            Planilha
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        v-if="report.files.csv!==''"
                        class="white--text"
                        :href="report.files.csv"
                        download
                        rounded
                        color="cyan"
                        target="_blank"
                      >
                        Download
                      </v-btn>
                      <v-btn
                        v-if="report.files.csv===''"
                        class="white--text"
                        download
                        rounded
                        color="cyan"
                        disabled
                      >
                        Download
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-row class="mx-3 mt-3">
              <p class="title font-weight-light">Solicitante</p>
            </v-row>

            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  label="Nome"
                  filled
                  outlined
                  disabled
                  color="light-green lighten-1"
                  v-model="report.name"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  filled
                  outlined
                  disabled
                  color="light-green lighten-1"
                  v-model="report.email"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  filled
                  outlined
                  disabled
                  color="light-green lighten-1"
                  v-model="report.requester"
                  label="Orgão"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  filled
                  outlined
                  disabled
                  color="light-green lighten-1"
                  v-model="report.office"
                  label="Orgão"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-autocomplete
                  label="Estado"
                  append-icon=""
                  ref="estadosBox"
                  filled
                  outlined
                  disabled
                  :items="estadosBoxItems"
                  color="light-green lighten-1"
                  v-model="report.state"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-combobox
                  label="Cidade(s)"
                  color="success"
                  v-model="report.city"
                  multiple
                  disabled
                  outlined
                  clearable
                  chips
                ></v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-row class="mx-3 mt-3">
              <p class="title font-weight-light">Observações adicionais</p>
            </v-row>
            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  filled
                  outlined
                  disabled
                  color="light-green lighten-1"
                  v-model="report.additionalInfo"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="5"></v-col>
              <v-col cols="3">
                <v-btn @click="updateData(report)" dark>
                  Editar
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-row align="center" justify="center">

              <v-col v-if="report.jurid_aprov=='0'">
                <v-card class="mx-auto" max-width="344" elevation="0">
                  <v-card-title align="center" justify="center">Aprovação de jurídico</v-card-title>
                  <v-card-subtitle align="center" justify="center">Aprovação do time de jurídico</v-card-subtitle>
                  <v-card-actions>
                    <v-btn @click="reprove(report, 'jurid')" outlined color="error">
                      Reprovar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="approve(report, 'jurid')" color="success">
                      Aprovar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col v-if="report.jurid_aprov!='0'">
                <v-card class="mx-auto" max-width="344" elevation="0">
                  <v-card-title>Aprovação de jurídico</v-card-title>
                  <v-card-actions>
                    <v-btn v-if="report.jurid_aprov=='1'" disabled>
                      Aprovado
                    </v-btn>
                    <v-btn v-if="report.jurid_aprov!='1'" disabled>
                      Reprovado
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
    <v-pagination
      v-model="page"
      :length="Math.ceil(reportsData.length/perPage)"
    ></v-pagination>
  </v-container>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

export default {
  data: () => ({
    reports: [[]],
    reportsData: [],
    visiblePages:"",
    page: 1,
    perPage: 5,
    estadosBoxItems: [
      { value: null, text: 'Selecione um estado' },
      { value: 'SP', text: 'São Paulo' },
      { value: 'RJ', text: 'Rio de Janeiro' },
      { value: 'AC', text: 'Acre' },
      { value: 'AL', text: 'Alagoas' },
      { value: 'AP', text: 'Amapá' },
      { value: 'AM', text: 'Amazonas' },
      { value: 'BA', text: 'Bahia' },
      { value: 'CE', text: 'Ceará' },
      { value: 'DF', text: 'Distrito Federal' },
      { value: 'ES', text: 'Espírito Santo' },
      { value: 'GO', text: 'Goiás' },
      { value: 'MA', text: 'Maranhão' },
      { value: 'MT', text: 'Mato Grosso' },
      { value: 'MS', text: 'Mato Grosso do Sul' },
      { value: 'MG', text: 'Minas Gerais' },
      { value: 'PA', text: 'Pará' },
      { value: 'PB', text: 'Paraíba' },
      { value: 'PR', text: 'Paraná' },
      { value: 'PE', text: 'Pernambuco' },
      { value: 'PI', text: 'Piauí' },
      { value: 'RN', text: 'Rio Grande do Norte' },
      { value: 'RS', text: 'Rio Grande do Sul' },
      { value: 'RO', text: 'Rondônia' },
      { value: 'RR', text: 'Roraima' },
      { value: 'SC', text: 'Santa Catarina' },
      { value: 'SE', text: 'Sergipe' },
      { value: 'TO', text: 'Tocantins' },
    ]
  }),
  methods: {
    separatefields(report) {
      report.time = report.time.split('~')
      report.city = report.city.split(',')
      report.names = report.names.split(',')
      report.links = report.links.split(',')
      report.problems = report.report.split(',')
      report.problemsKeywords = []
      report.keywords.split('&').forEach((keywordsItem) => {
        report.problemsKeywords.push(keywordsItem)
      })

      return report
    },
    joinfields(report) {
      const keywords = report.problemsKeywords.map(item => item).join(' & ')
      report.keywords = keywords
      return report
    },
    async sendmodification(report) {
      const dataReport = {... this.joinfields(report)}
      dataReport.links = dataReport.links.join(',')
      dataReport.city = dataReport.city.join(',')
      dataReport.time = dataReport.time.join('~')
      dataReport.names = dataReport.names.join(',')
      dataReport.report = dataReport.problems.join(',')

      const options = {
        method: 'PATCH',
        url: process.env.VUE_APP_SERVICE_NAME + `api/relatorios/${dataReport.id}/`,
        headers: {
          'content-type': 'application/json',
          authorization: "Token " + localStorage.getItem("token")
        },
        data: dataReport
      };

      return await axios.request(options).catch((error) => {
        console.error(error);
        this.$swal.fire('Erro em atualizar os dados', '', 'error')
        throw error
      });
    },
    updateData(report) {
      this.$router.push({path: '/modifyreport', query: {modifyreport: report}})
    },
    approve(report, team) {
      this.$swal.fire({
        title: 'Confirmar aprovação de dados?',
        icon: 'info',
        showDenyButton: true,
        confirmButtonText: `Aprovar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          if (team == "jurid") {
            report.jurid_aprov = "1"
          }
          this.sendmodification(report).then(() => {
            this.$swal.fire('Dados Aprovados!', '', 'success')
          })
        }
      })
    },
    reprove(report, team) {
      this.$swal.fire({
        title: 'Descreva o motivo da reprovação',
        input: 'textarea',
        inputPlaceholder: 'Motivo',
        showDenyButton: true,
        confirmButtonText: `Reprovar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          if (team == "jurid") {
            report.jurid_aprov = result.value
            if (!report.jurid_aprov){
              report.jurid_aprov = '0'
              this.$swal.fire('Preencha o motivo!', '', 'error')
              return
            }
          }

          this.sendmodification(report).then(() => {
            this.$swal.fire('Dados Reprovados!', '', 'success')
          })
        }
      })
    }
  },
  checkEmailDomain(email){

    axios
    .get(process.env.VUE_APP_SERVICE_NAME + "api/accounts/users/allowed_domains?domain=" +email.split("@")[1] , {
      headers: {
        Authorization: "Token " + localStorage.getItem("token")
      }
    }).then(response => {
      var domain_allowed = response.data['domain'];
      if (domain_allowed.length < 0) {
        return false
      }
    })
  },
  mounted() {
    axios.get(process.env.VUE_APP_SERVICE_NAME + "api/filtered_reports/", {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(response => {
        for (var x in response.data['results']) {
          var report = response.data['results'][x]
          report = this.separatefields(report);
          report.files = {
            pdf: '',
            sheet: '',
            csv: ''
          }
          this.reportsData.push(report);
        }
        for (var i = 0; i < this.reportsData.length; i+=this.perPage)     {
            this.reports.push(this.reportsData.slice(i,i+this.perPage));
        }
        
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        this.reportsData.map(async (report, index) => {
          const fileId = report['files_id'];
          await axios
            .get(process.env.VUE_APP_SERVICE_NAME + "api/files/" + fileId, {
              headers: {
                Authorization: "Token " + localStorage.getItem("token")
              }
            })
            .then(response => {
                if (response.data){
                  report.files = {
                    pdf: response.data.pdf,
                    sheet: response.data.sheet,
                    csv: response.data.csv
                  }
                }
              Vue.set(this.reportsData, index, report)
            });
          return report;
        });
    });
  }
};
</script>
