<template>
  <div id="app">
    <v-app>
      <v-app-bar
      app
      color=#F4F4F4
    >

      <v-container fluid>
        <v-row class="mt-4">
          <v-col cols="2">
            <router-link :to="{name:'Formview'}">
              <v-img
                alt="RA Logo"
                class="shrink hidden-sm-and-down"
                contain
                min-width="200"
                src="https://www.reclameaqui.com.br/_nuxt/img/559b7e9.svg"
                width="200"
              />
            </router-link>
          </v-col>
          <v-col cols="5">
            <p class="text-right headline font-weight-regular grey--text">Solicitação de relatório - MP/DP</p>
          </v-col>
          <v-col cols="3">
            <p v-if="userEmail()" class="mt-1 text-right"> {{ userName() }} ({{ userEmail() }})</p>
          </v-col>
          <v-col cols="1">
            <p v-if="userEmail()" class="mt-1 text-right"> Menu </p>
          </v-col>
          <v-col cols="1">
            <v-menu v-if="userEmail()"
              bottom
              left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mt-n2"
                  v-bind="attrs"
                  v-on="on" >
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="groupRa()">
                  <router-link :to="{name:'Reportsview'}">
                    <v-list-item-title>Avaliar Relatórios</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link :to="{name:'MyReportview'}">
                    <v-list-item-title>Meus Relatórios</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link :to="{name:'Formview'}">
                    <v-list-item-title>Criar Relatório</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link :to="{name:'Helpview'}">
                    <v-list-item-title>Ajuda</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link :to="{name:'UpdateUserview'}">
                    <v-list-item-title>Atualizar Usuário</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-title>Sair</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <router-view/>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  methods: {
    userName(){
      const userName = localStorage.getItem('name');
      return userName;
    },
    userEmail(){
      const userEmail = localStorage.getItem('email');
      return userEmail;
    },
    groupRa(){
      return localStorage.getItem('group') === 'ReclameAqui'
    },
    logout(){
      const options = {
        method: 'GET',
        url: process.env.VUE_APP_SERVICE_NAME + 'api/accounts/logout/',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Token ' + localStorage.getItem('token'),
        }
      };

      axios.request(options)
        .then(() => {
          localStorage.clear();
          this.$router.push({name:'Loginview'});
        }).catch((error) => {
        console.error(error);
      });
    }
  },
  async created() {
    axios.get(process.env.VUE_APP_SERVICE_NAME + "ht/",
      {
        "content-type": "application/x-www-form-urlencoded",
      }
    );

    const token = localStorage.getItem('token');
    const name = localStorage.getItem('name');
    const email = localStorage.getItem('email');

    if ((!String(email || '').trim() || !String(name || '').trim()) && token){
      const options = {
        method: 'GET',
        url: process.env.VUE_APP_SERVICE_NAME + "api/accounts/users/me/",
        headers: {authorization: "Token " + localStorage.getItem('token')}
      };
      await axios.request(options).then((response) => {
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('name', response.data.first_name + ' ' + response.data.last_name);
        this.$forceUpdate()
      }).catch(function (error) {
        console.error(error);
      });
    }
  }
}
</script>
