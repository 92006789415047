<template>
  <v-container fluid class="relative">
    <template v-if="isWorking">
      <div class="mt-n3 d-flex row scroll-bar">
        <v-progress-linear indeterminate color="blue darken-2" />
      </div>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center" justify="center">
        <v-col cols="7">
          <v-container align-center class="mt-5 white rounded-lg">
            <p class="ml-3 mt-4 subheading">Esqueci a Senha</p>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="userForm.email"
                  label="Email"
                  placeholder="Email"
                  outlined
                  color="light-green lighten-1"
                  :rules="emailFieldRules"
                ></v-text-field>
              </v-col>
            </v-row>

          </v-container>
          <v-row class="mt-5" align="center" justify="center">
            <v-btn
              color="success"
              :disabled="!valid || isWorking"
              @click="validate"
              :loading="isWorking"
            >
              Enviar
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Form',
  components: {

  },
  data: () => ({
    userForm: {
      email: ''
    },
    forgetPasswordPost: {
      email: ''
    },
    isWorking: false,
    valid: true,
    emailFieldRules: [
      (v) => !!v || 'Email é necessário',
      (v) => /.+@.+\..+/.test(v) || 'Email deve ser um email válido',
    ]
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
      this.$nextTick(this.sendData);
    },
    async sendData() {
      if (!this.valid){
        this.$swal.fire({
          icon: 'error',
          title: `Por favor, verifique os campos preenchidos.`,
        });
        return;
      }
      this.isWorking = true;

      this.forgetPasswordPost = {
        email: this.userForm.email
      }

      await axios
        .post(process.env.VUE_APP_SERVICE_NAME + 'api/accounts/password/reset/',this.forgetPasswordPost)
        .then(() => {
          this.$swal.fire({
            icon: 'success',
            title: `Solicitação enviada.`,
            text: `Em breve você receberá um e-email com as instruções.`
          });
          window.scrollTo(0,0);
          this.$refs.form.reset();
          this.$router.push({name: 'Loginview'});
        })
        .catch((e) => {
          this.isWorking = false;
          this.$swal.fire({
            icon: 'error',
            title: `Erro ao enviar dados`,
            text: e.response.data.detail ? e.response.data.detail : `Favor verificar os campos.`
          });
          console.log(e);
        })
        .finally(() => {
          this.isWorking = false;
          this.$refs.recaptcha.reset();
        });
    },
  }
};
</script>

<style>
.scroll-bar {
  position: absolute !important;
  top: 150px;
  left: 0;
  z-index: 150;
}
</style>
