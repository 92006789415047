<template>
    <v-main class="grey lighten-2">
        <Login/>
    </v-main>
</template>

<script>
import Login from "@/components/Login";


export default {
  name: "Loginview",

  components: {
    Login,
  },

  data: () => ({
    //
  }),
};
</script>
