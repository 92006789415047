<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="8">
        <v-banner
          class="rounded-lg"
          justify="center"
          elevation='2'
          color="white">

          <p class="ml-3 mt-4 subheading">(<a class="red--text">*</a>) campos obrigatórios.</p>

          <v-card class="mx-auto" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><p class="font-weight-black">Órgão solicitante <a class="red--text">*</a></p></v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Escolher um orgão definido ou descrever na opção <strong>Outro</strong>.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><p class="font-weight-black">Arquivos</p></v-list-item-title>
                <v-list-item-title>Ofício <a class="red--text">*</a></v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Arquivo no formato <strong>pdf</strong>.</v-list-item-subtitle>
                <v-list-item-title class="mt-4">Planilha</v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Arquivo no formato <strong>csv</strong> ou <strong>xlsx</strong>.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><p class="font-weight-black">Empresa <a class="red--text">*</a></p></v-list-item-title>
                <v-list-item-title>Nome</v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Nome da empresa que constará no relatório. <strong>Exemplo: Reclame AQUI</strong>.</v-list-item-subtitle>
                <v-list-item-title class="mt-4">Link</v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Link da empresa no Reclame Aqui. <strong>Exemplo: https://www.reclameaqui.com.br/empresa/reclameaqui/</strong></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><p class="font-weight-black">Relatório <a class="red--text">*</a></p></v-list-item-title>
                <v-list-item-title>Problema</v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Título do problema que você deseja procurar.</v-list-item-subtitle>
                <v-list-item-title class="mt-4">Palavras-chave</v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Palavras-chave são utilizadas para filtrar as reclamações:</v-list-item-subtitle>
                <v-banner
                  class="rounded mt-1"
                  justify="center"
                  elevation='2'
                  color="#cfcfcf">
                  <p class="mt-1 subheading">Operador lógico <strong>E</strong>: </p>
                  <p class="ml-4">
                    O operador <strong>E</strong> resulta em um valor verdadeiro quando todos os termos de entrada forem encontrados. Exemplo:
                  </p>
                  <p class="font-italic ml-6">
                    dinheiro <strong>E</strong> reembolso <strong>E</strong> cartão
                  </p>
                </v-banner>
                <v-banner
                  class="rounded mt-1"
                  justify="center"
                  elevation='2'
                  color="#cfcfcf">
                  <p class="mt-1 subheading">Operador lógico <strong>OU</strong>: </p>
                  <p class="ml-4">
                    O operador <strong>OU</strong> resulta em um valor verdadeiro quando ao menos um dos termos de entrada forem encontrados. Exemplo:
                  </p>
                  <p class="font-italic ml-6">
                    dinheiro <strong>OU</strong> reembolso <strong>OU</strong> cartão
                  </p>
                </v-banner>
                <v-banner
                  class="rounded mt-1"
                  justify="center"
                  elevation='2'
                  color="#cfcfcf">
                  <p class="mt-1 subheading">Operador lógico <strong>NÃO</strong>: </p>
                  <p class="ml-4">
                    O operador <strong>NÃO</strong> ( representado na expressão pelo símbolo <strong>!</strong> ) deve ser utilizado quando se deseja que o termo não seja encontrado. Exemplo:
                  </p>
                  <p class="font-italic ml-6">
                    <strong>!</strong> reembolso
                  </p>
                </v-banner>
                <v-banner
                  class="rounded mt-1"
                  justify="center"
                  elevation='2'
                  color="#cfcfcf">
                  <p class="mt-1 subheading">Operadores de precedência parênteses <strong>(</strong>  <strong>)</strong>: </p>
                  <p class="ml-4">
                    Os <strong>parênteses</strong> podem ser utilizados para indicar agrupamento e precedência na expressão. Exemplo:
                  </p>
                  <p class="font-italic ml-6">
                    <strong>(</strong> reembolso <strong>E</strong> dinheiro <strong>)</strong> <strong>OU</strong> cartão
                  </p>
                </v-banner>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><p class="font-weight-black">Estado <a class="red--text">*</a></p></v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Escolher um estado para a busca de reclamações ou definir <strong>Todos</strong> para não restringir.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><p class="font-weight-black">Período <a class="red--text">*</a></p></v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Escolher um perído definido ou definir um via calendário.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-divider></v-divider>
            </v-row>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><p class="font-weight-black">Observação</p></v-list-item-title>
                <v-list-item-subtitle class="ml-5 mt-1">Detalhar algumas informações para solicitação.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-banner>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>
