<template>
  <v-container fluid class="relative">
    <template v-if="isWorking">
      <div class="mt-n3 d-flex row scroll-bar">
        <v-progress-linear indeterminate color="blue darken-2" />
      </div>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center" justify="center">
        <v-col cols="7">
          <v-container align-center class="mt-5 white rounded-lg">
            <p class="ml-3 mt-4 subheading">Atualizar usuário</p>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="userForm.name"
                  label="Nome"
                  placeholder="Nome completo"
                  outlined
                  color="light-green lighten-1"
                  :rules="nameFieldRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="userForm.aggency"
                  label="Orgão"
                  placeholder="Nome do orgão completo"
                  outlined
                  color="light-green lighten-1"
                  :rules="agencyFieldRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="userForm.office"
                  label="Cargo"
                  placeholder="Cargo"
                  outlined
                  color="light-green lighten-1"
                  :rules="officeFieldRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-select
                  ref="userstateBox"
                  :items="userstateBoxItems"
                  label="Selecionar estado"
                  color="success"
                  v-model="userForm.state"
                  :rules="userstateSelectRule"
                ></v-select>
              </v-col>
            </v-row>

          </v-container>
          <v-row class="mt-5" align="center" justify="center">
            <v-btn
              color="success"
              :disabled="!valid || isWorking"
              @click="validate"
              :loading="isWorking"
            >
              Enviar
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UpdateUser',
  components: {

  },
  data: () => ({
    userstateBoxItems: [
      { value: null, text: 'Selecione um estado' },
      { value: 'AC', text: 'Acre' },
      { value: 'AL', text: 'Alagoas' },
      { value: 'AP', text: 'Amapá' },
      { value: 'AM', text: 'Amazonas' },
      { value: 'BA', text: 'Bahia' },
      { value: 'CE', text: 'Ceará' },
      { value: 'DF', text: 'Distrito Federal' },
      { value: 'ES', text: 'Espírito Santo' },
      { value: 'GO', text: 'Goiás' },
      { value: 'MA', text: 'Maranhão' },
      { value: 'MT', text: 'Mato Grosso' },
      { value: 'MS', text: 'Mato Grosso do Sul' },
      { value: 'MG', text: 'Minas Gerais' },
      { value: 'PA', text: 'Pará' },
      { value: 'PB', text: 'Paraíba' },
      { value: 'PR', text: 'Paraná' },
      { value: 'PE', text: 'Pernambuco' },
      { value: 'PI', text: 'Piauí' },
      { value: 'RJ', text: 'Rio de Janeiro' },
      { value: 'RN', text: 'Rio Grande do Norte' },
      { value: 'RS', text: 'Rio Grande do Sul' },
      { value: 'RO', text: 'Rondônia' },
      { value: 'RR', text: 'Roraima' },
      { value: 'SC', text: 'Santa Catarina' },
      { value: 'SP', text: 'São Paulo' },
      { value: 'SE', text: 'Sergipe' },
      { value: 'TO', text: 'Tocantins' },
    ],
    userForm: {
      name: '',
      aggency: '',
      office: '',
      state: ''
    },
    updateUserPost: {
      first_name: '',
      last_name: '',
      aggency: '',
      office: '',
      state: ''
    },
    isWorking: false,
    valid: true,
    recaptchaToken: '',
    nameFieldRules: [
      (v) => !!v || 'Nome é necessário',
      (v) => v?.length >= 10 || 'Nome não pode ser menor que 10 caracteres',
    ],
    officeFieldRules: [
      (v) => !!v || 'Informe o cargo',
    ],
    agencyFieldRules : [
      (v) => !!v || 'Nome completo do orgão é necessário',
    ],
  }),
  mounted: function () {
    this.$nextTick(function () {
        this.getData() 
    })
  },
  methods: {
    onCaptchaVerified(recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    userName(){
      const userName = localStorage.getItem('name');
      return userName;
    },
    userToken(){
      const userToken = localStorage.getItem('token');
      return userToken;
    },
    userEmail(){
      const userEmail = localStorage.getItem('email');
      return userEmail;
    },
    validate() {
      this.$refs.form.validate();
      this.$nextTick(this.sendData);
    },
    async getData(){
        const options = {
              method: 'GET',
              url: process.env.VUE_APP_SERVICE_NAME + "api/accounts/users/me/",
              headers: {authorization: "Token " + this.userToken()}
            };
        await axios.request(options).then((response) => {
            this.userForm.name = response.data.first_name + ' ' + response.data.last_name
            this.userForm.aggency = response.data.aggency
            this.userForm.office = response.data.office
            this.userForm.state = response.data.state
        }).catch(function (error) {
              console.error(error);
            });
    },
    async sendData() {
      if (!this.valid){
        this.$swal.fire({
          icon: 'error',
          title: `Por favor, verifique os campos preenchidos.`,
        });
        return;
      }
      this.isWorking = true;

      const firstName = this.userForm.name.split(' ')[0];
      const lastName = this.userForm.name.replace(firstName,'');

      this.updateUserPost = {
        first_name: firstName,
        last_name: lastName,
        email: this.userEmail(),
        office: this.userForm.office,
        aggency: this.userForm.aggency,
        state: this.userForm.state
      }

      await axios
        .post(process.env.VUE_APP_SERVICE_NAME + 'api/accounts/users/me/change/',this.updateUserPost,{
            headers: {authorization: "Token " + this.userToken(),
              "content-type": "application/json"
            }}
        )
        .then(() => {
          this.$swal.fire({
            icon: 'success',
            title: `Sucesso!`,
            text: `Usuário atualizado com sucesso.`
          });
          window.scrollTo(0,0);
          localStorage.setItem('name', this.updateUserPost.first_name + ' ' + this.updateUserPost.last_name);
          this.$router.push({name: 'Formview'});
        })
        .catch((e) => {
          this.isWorking = false;
          this.$swal.fire({
            icon: 'error',
            title: `Erro ao enviar dados`,
            text: e.response.data.detail ? e.response.data.detail : `Favor verificar os campos.`
          });
          console.log(e);
        })
        .finally(() => {
          this.isWorking = false;
          this.$refs.recaptcha.reset();
        });
    },
  }
};
</script>

<style>
.scroll-bar {
  position: absolute !important;
  top: 150px;
  left: 0;
  z-index: 150;
}
</style>
