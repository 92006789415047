<template>
  <v-container fluid>
    <template v-if="isWorking">
      <div class="mt-n3 d-flex row">
        <v-progress-linear indeterminate color="blue darken-2" />
      </div>
    </template>

    <v-form ref="form" lazy-validation>
      <v-row class="mt-5" align="center" justify="center">
        <v-col cols="4">
          <v-text-field
            label="Seu Email"
            outlined
            v-on:keyup.enter="validate"
            color="light-green lighten-1"
            v-model="emailField"
            :rules="emailFieldRules"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="4">
          <v-text-field
            label="Senha"
            outlined
            v-on:keyup.enter="validate"
            color="light-green lighten-1"
            v-model="passwordField"
            type="password"
            :rules="passemailFieldRules"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-btn
          color="success"
          :disabled="isWorking"
          @click="validate">
          Entrar
        </v-btn>
      </v-row>

      <v-row align="center" justify="center">
        <p class="ml-3 mt-4 subheading"><router-link :to="{name:'CreateUserview'}">Criar conta</router-link></p>
        <p class="ml-3 mt-4 subheading"><router-link :to="{name:'ForgetPasswordview'}">Esqueci a Senha</router-link></p>
      </v-row>

    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    isWorking: false,
    emailField: "",
    passwordField: "",
    emailFieldRules: [
      (v) => !!v || "Email é necessário",
      (v) => /.+@.+\..+/.test(v) || "Email deve ser um email válido",
    ],
    passemailFieldRules: [(v) => !!v || "Senha é necessário"]
  }),
  methods: {
    async validate() {
      this.$refs.form.validate();

      if (this.emailField && this.passwordField) {
        this.isWorking = true;
        await axios
          .post(
            process.env.VUE_APP_SERVICE_NAME + "api/accounts/login/",
            {
              email: btoa(this.emailField),
              password: btoa(this.passwordField),
            },
            {
              "content-type": "application/x-www-form-urlencoded",
            }
          )
          .then(async (response) => {
            localStorage.setItem("token", response.data.token)

            const options = {
              method: 'GET',
              url: process.env.VUE_APP_SERVICE_NAME + "api/accounts/users/me/",
              headers: {authorization: "Token " + response.data.token}
            };

            await axios.request(options).then((response) => {
              if (response.data.groups.length == 0){
                localStorage.setItem('group', 'MP')
              }else{
                localStorage.setItem('group', response.data.groups[0].name)
              }
              localStorage.setItem('email', response.data.email);
              localStorage.setItem('name', response.data.first_name + ' ' + response.data.last_name);

              this.$swal.fire({
                icon: "success",
                title: `Seja bem vindo ${this.emailField.split('@')[0]}`,
              });

              this.$refs.form.reset();
              this.$router.push({name: 'Formview'});
            }).catch(function (error) {
              console.error(error);
            });
          })
          .catch((e) => {
            this.$swal.fire({
              icon: "error",
              title: `Email ou senha não estão corretos`,
            });
            console.log(e);
          });

        this.isWorking = false;
      }
    },
  },
};
</script>
