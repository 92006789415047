<template>
    <v-main class="grey lighten-2">
        <Help/>
    </v-main>
</template>

<script>
import Help from "@/components/Help";

export default {
  name: "Helpview",

  components: {
    Help,
  },

  data: () => ({
    //
  }),
};
</script>
