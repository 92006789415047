<template>
    <v-main class="grey lighten-2">
        <UpdateUser/>
    </v-main>
</template>

<script>
import UpdateUser from "@/components/UpdateUser";

export default {
  name: "UpdateUserview",

  components: {
    UpdateUser,
  },

  data: () => ({
    //
  }),
};
</script>
