import Vue from "vue";
import VueRouter from "vue-router";
import Formview from "../views/Formview.vue";
import Loginview from "../views/Loginview.vue";
import Reportsview from "../views/Reportsview.vue";
import Helpview from "../views/Helpview.vue";
import MyReportview from "../views/MyReportview";
import CreateUserview from "../views/CreateUserview";
import UpdateUserview from "../views/UpdateUserview";
import ForgetPasswordview from "../views/ForgetPasswordview";
import NewPasswordview from "../views/NewPasswordview";
import VerifyUserview from "../views/VerifyUserview";
import DownloadFileview from "../views/DownloadFileview";
import ModifyReportview from "../views/ModifyReportview";


Vue.use(VueRouter);

const routes = [
  {
    path: "/help",
    name: "Helpview",
    component: Helpview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/myReport",
    name: "MyReportview",
    component: MyReportview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/",
    name: "Formview",
    component: Formview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/reports",
    name: "Reportsview",
    component: Reportsview,
    meta: {
      requiresAuth: true,
      is_admin: true
    }
  },
  {
    path: "/modifyreport",
    name: "ModifyReportview",
    component: ModifyReportview,
    meta: {
      requiresAuth: true,
      is_admin: true
    }
  },
  {
    path: "/updateuser",
    name: "UpdateUserview",
    component: UpdateUserview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Loginview",
    component: Loginview,
    meta: {
      guest: true
    }
  },
  {
    path: "/newUser",
    name: "CreateUserview",
    component: CreateUserview
  },
  {
    path: "/newUser/verify",
    name: "VerifyUserview",
    component: VerifyUserview
  },
  {
    path: "/forgetPassword",
    name: "ForgetPasswordview",
    component: ForgetPasswordview
  },
  {
    path: "/newPassword",
    name: "newPasswordview",
    component: NewPasswordview
  },
  {
    path: "/downloadFile",
    name: "DownloadFileview",
    component: DownloadFileview,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      });
    } else {
      if (to.matched.some(record => record.meta.is_admin)) {
        if (localStorage.getItem("group") == "ReclameAqui") {
          next();
        } else {
          next({ name: "Formview" });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem("token") == null) {
      next();
    } else {
      next({ name: "Formview" });
    }
  } else {
    next();
  }
});

export default router;
