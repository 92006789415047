<template>
    <v-main class="grey lighten-2">
        <DownloadFile/>
    </v-main>
</template>

<script>
import DownloadFile from "@/components/DownloadFile";


export default {
  name: "DownloadFileview",

  components: {
    DownloadFile,
  },

  data: () => ({
    //
  }),
};
</script>
