<template>
    <v-main class="grey lighten-2">
        <MyReport/>
    </v-main>
</template>

<script>
import MyReport from "@/components/MyReport";

export default {
  name: "MyReportview",

  components: {
    MyReport,
  },

  data: () => ({
    //
  }),
};
</script>
