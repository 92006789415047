<template>
    <v-main class="grey lighten-2">
        <Report/>
    </v-main>
</template>

<script>
import Report from "@/components/Report";


export default {
  name: "Reportsview",

  components: {
    Report,
  },

  data: () => ({
    //
  }),
};
</script>
