<template>
    <v-main class="grey lighten-2">
        <ModifyReport/>
    </v-main>
</template>

<script>
import ModifyReport from "@/components/ModifyReport";


export default {
  name: "ModifyReportview",

  components: {
    ModifyReport,
  },

  data: () => ({
    //
  }),
};
</script>
