<template>
    <v-main class="grey lighten-2">
        <VerifyUser/>
    </v-main>
</template>

<script>
import VerifyUser from "@/components/VerifyUser";


export default {
  name: "VerifyUserview",

  components: {
    VerifyUser,
  },

  data: () => ({
    //
  }),
};
</script>
